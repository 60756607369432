import { Toast } from '@js/vendors/bootstrap';
export const domReady = callBack => {
  if (document.readyState === "loading") {
    document.addEventListener('DOMContentLoaded', callBack);
  } else {
    callBack();
  }
};
export const taggedTemplate = (strings, ...keys) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals#tagged_templates
  return (...values) => {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join("");
  };
};
const toastTemplate = taggedTemplate`
<div class="toast mb-3 mb-md-5" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="d-flex">
    <div class="toast-body mx-auto">${'message'}</div>
    <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</div>`;
export const toggleNotification = (message, opts) => {
  const toastsContainer = document.getElementById('toast-container');
  const toastString = toastTemplate({
    message
  }).trim();
  toastsContainer.insertAdjacentHTML('afterbegin', toastString);
  const toast = new Toast(toastsContainer.firstChild, opts);
  toast.show();
};
export const smoothScrollTo = to => {
  const target = document.querySelector(to);
  if (!target) {
    return;
  }
  const navbarHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--bs-navbar-height-px'));
  window.scrollTo({
    top: target.getBoundingClientRect().top + window.scrollY - navbarHeight,
    behavior: 'smooth'
  });
  target.focus();
  if (!target.matches(':focus')) {
    target.tabIndex = '-1'; // Adding tabindex for elements not focusable
    target.focus(); // Set focus again
  }
};

export const loadScript = (url, id, attrs = {
  'async': true
}) => {
  if (document.getElementById(id)) return;
  return new Promise(resolve => {
    // https://stackoverflow.com/questions/16230886/trying-to-fire-the-onload-event-on-script-tag/16231055#16231055
    var script = document.createElement('script');
    script.id = id;
    for (let attr in attrs) {
      script.setAttribute(attr, attrs[attr]);
    }
    document.head.appendChild(script);
    script.onload = () => resolve(script);
    script.src = url;
  });
};
export const fromDataKeys = (data, subString) => {
  /**
  * Given a data object tipically returned by el.dataset(), returns a new
  * object filtered by keys that .startwith(subString) and removing
  * subString from return value's key
  */
  return Object.fromEntries(Object.entries(data).filter(([key, value]) => key.startsWith(subString) && key !== subString).map(([key, value]) => {
    let newKey = key.replace(subString, '');
    return [newKey.charAt(0).toLowerCase() + newKey.slice(1), value];
  }));
};